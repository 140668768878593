var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        { name: "show", rawName: "v-show", value: true, expression: "true" }
      ],
      staticClass: "rounded"
    },
    [
      _c(
        "CCard",
        { staticClass: "p-0 m-0 rounded" },
        [
          _c(
            "CCardBody",
            { staticClass: "p-0 m-0 rounded h-100 overflow-hidden" },
            [
              _c("GrafanaEmbeddedDashboard", {
                attrs: { src: _vm.urlPath, from: "now-24h", to: "now" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }