<template>
  <CRow>
    <CCol>
      <CCard>
        <CCardHeader :color="$theme.backColor" text-color="light">
          Rules Evaluated
        </CCardHeader>
      <GrafanaEmbeddedDashboard
        :src="urlPath"
        :from="range.from"
        :to="range.to"
        
        
        
        kiosk=""
        :truckID="deviceId"
      />
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { faTrophy } from "@fortawesome/free-solid-svg-icons";

import GrafanaEmbeddedDashboard from "@/components/grafana/GrafanaEmbeddedDashboard";
import BaseGrafanaDashboard from "@/components/grafana/BaseGrafanaDashboard";
export default {
  name: "RulesMetrics",
  components: {
    GrafanaEmbeddedDashboard,
    BaseGrafanaDashboard,
  },
  data() {
    return {
      selectedDevice: undefined,
      selectedDashboard: undefined,
      _urlPath: undefined,
      range: {
        from: Date.now() - (3600*1000*24)*7,
        to: Date.now(),
      },
    };
  },
  computed: {

    deviceId(){
      return this.selectedDevice?this.selectedDevce.device:""; 
    },
    urlPath(){

      return "https://cleanharbors-lonestar.aarcomm.io/api/grafana/d/PPlo_2o7k/rule-evaluations?orgId=6" 
      if(!this.selectedDevice)  return ; 
      let temp = this.selectedDashboard; 
       //If Not Found in user Database OR value is Undefined, use default 
      if(!temp || !temp.url){
        temp = defaultHistoricalDashboard; 
        temp += "&var-TruckID=" + this.device.device;
        return temp; 
      }
         //temp = temp.value; 
         return temp.url; 
    }
  },
  methods: {
    async load(params) {
      try {
        console.log(this.$route);
        let id = this.$route.params.id;

        this.selectedDevice =  this.$store.getters.inventory
          .find((item) => item.device === id);
        /*
        this.selectedDashboard = this.$store.getters.dashboards["realtime"].find(
          (item) => item.device === id
        );
        */
      } catch (err) {
        console.log(err);
      }
      //console.log(this.selectedDashboard, this.selectedDevice);
    },
  },
  async mounted() {
    await this.$nextTick(()=>this.load());
  },
  async activated() {
    await this.$nextTick(()=>this.load());
  },
};
</script>