

<template>
  <!-- Cant Get this to draw correctly in the Card!!! -->
  <div v-show="true" class="rounded">
    <CCard class="p-0 m-0 rounded">
      <CCardBody class="p-0 m-0 rounded h-100 overflow-hidden">
        <GrafanaEmbeddedDashboard :src="urlPath" from="now-24h" to="now">
        </GrafanaEmbeddedDashboard>
      </CCardBody>
    </CCard>
  </div>
</template>


<script>
import GrafanaEmbeddedDashboard from "./GrafanaEmbeddedDashboard.vue";

export default {
  name: "BaseGrafanaDashboard",
  props: {},
  components: {
    GrafanaEmbeddedDashboard
  },
  data() {
    return {
      //selected: undefined,
      key: "",
      //dashboardList:[], 
    };
  },
  computed: {
    dashboardList(){
       return this.mapData(this.$store.state.dataStore.dashboards[this.key]);
        //return this.$store.state.dataStore.dashboards;
    },
  },
  methods: {
    isEmpty(key) {
      if (
        !this.$store.state.dataStore.dashboards ||
        !this.$store.state.dataStore.dashboards[key] ||
        this.$store.state.dataStore.dashboards[key].length === 0
      ) {
        return true;
      }
      return false;
    },
    mapData(dashData) {
      let temp = dashData.map(x => {
        return { id: x.device, value: x.url };
      });
      return temp;
    }
  }
};
</script>