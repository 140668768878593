var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        [
          _c(
            "CCard",
            [
              _c(
                "CCardHeader",
                {
                  attrs: { color: _vm.$theme.backColor, "text-color": "light" }
                },
                [_vm._v(" Rules Evaluated ")]
              ),
              _c("GrafanaEmbeddedDashboard", {
                attrs: {
                  src: _vm.urlPath,
                  from: _vm.range.from,
                  to: _vm.range.to,
                  kiosk: "",
                  truckID: _vm.deviceId
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }